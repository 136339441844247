export const HEARTBEAT_INTERVAL = 5000;
export const SET_THEME = 'SET_THEME';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_SETTINGS = 'SET_SETTINGS';
export const CHANGE_BITRATE = 'CHANGE_BITRATE';
export const CHANGE_VOLUME = 'CHANGE_VOLUME';
export const REQUEST_HEARTBEAT = 'REQUEST_HEARTBEAT';
export const RECEIVE_HEARTBEAT = 'RECEIVE_HEARTBEAT';
export const REQUEST_STREAMS = 'REQUEST_STREAMS';
export const RECEIVE_STREAMS = 'RECEIVE_STREAMS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
